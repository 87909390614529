import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { GraphNavigationService, RegistrationEdge } from '@app/registration/graph/graph-navigation.service';

import { GraphNavigationStorageService } from '../graph-navigation-storage.service';
import { BookAppointmentNode } from '../nodes/book-appointment.node';

export enum PostRegistrationAction {
  BOOKING = 'booking',
}
@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToBookAppointmentEdge implements RegistrationEdge {
  nextNode = BookAppointmentNode;

  constructor(
    private graphNavigationService: GraphNavigationService,
    private graphNavigationStorageService: GraphNavigationStorageService,
  ) {}

  private hasBookingParam(): boolean {
    const graphNavigationArgs = this.graphNavigationStorageService.fetchGraphNavigationArgs();

    return graphNavigationArgs?.postRegistrationAction === PostRegistrationAction.BOOKING;
  }

  shouldNavigate$(): Observable<boolean> {
    return observableOf(this.hasBookingParam());
  }
}
